import SessionData from '../../utils/sessionData'
import {ShowHideTab} from 'app/utils/showHideTab'

const initialState = {
  user: {},
  theme: {},
  lastApiCallTime: {},
  getUserInfo: {},
  checkTokenMail :{},
  resetPassword : {},
}


export default function Login(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_IN':
      return {...state, user:{apiStatus:1,},loggingIn:true }

      case 'SSO_LOGIN_IN':
      return {...state, SSOuser:action.payload,loggingIn:true }
case 'SSO_LOGIN_IN_RESPONCE':
  return {...state, SSOuserData:action.payload }
case 'LOGIN_SUCCESS':
    SessionData.setItem('user',action.payload)
 return {...state, user:{userGroup:action.payload,apiStatus:0}, isFirstLogin: true,loggedIn:true }
case 'COMPONENT_LOADER_LOGIN':
    return {...state,loaderLogin:action.payload}
  case 'LOGIN_FAILED':
      return {...state, user: {errorMsg:action.payload,apiStatus:-1,},loggInFail:true }
    case 'SIGNUP_IN':
      return {...state, user:{apiStatus:1},loggInFail:true }
case 'SIGNUP_SUCCESS':
    window.location.href = "#/dashboard/overview/:param=freturn";
 return {...state, user: {userGroup:action.payload,apiStatus:1} }
case 'SIGNUP_FAILED':
      return {...state, user: {errorMsg:action.payload,apiStatus:-1} }

  case 'GETTING_THEME':
      return {...state, theme:{apiStatus:1} }

  case 'GOT_THEME_SUCCESS':
    return {...state, theme:action.payload, gotTheme: true }

  case 'GET_BROKER_SERVICE_LIST':
  case 'FAILED_GET_BROKER_SERVICE_LIST':
    return {...state, brokerServiceList: action.payload}  
    
  case 'CREATE_LEAD':
  case 'FAILED_TO_CREATE_LEAD':
    return {...state, createLead:action.payload} 
    
  case 'CREATE_LEAD_LOADER':
    return {...state, createLeadLoader:action.payload}   

  case 'THEME_FAILED':
      return {...state, theme:{errorMsg:action.payload,apiStatus:-1}, gotTheme: false }
  case 'COMPONENT_LOADER_FORGET_PASSWORD':
      return {...state,loaderForgetPassword:action.payload}
 
   case 'LAST_API_CALL_TIME':
      return {...state, lastApiCallTime:action.payload }
       case 'GETTING_USER':
      return {...state, user:{apiStatus:1},loggingIn:true }

  case 'GOT_USER_SUCCESS':
  SessionData.setItem('user',action.payload)
    return {...state, user:action.payload,loggedIn:true }

  case 'USER_FAILED':
      return {...state, user:{errorMsg:action.payload,apiStatus:-1
      },loggInFail:true }


  case 'GETTING_USER_INFO':
      return {...state, getUserInfo:{apiStatus:1} }

  case 'GOT_USER_INFO_SUCCESS':
    return {...state, getUserInfo:{data:action.payload,apiStatus:0} }

  case 'USER_INFO_FAILED':
      return {...state, getUserInfo:{errorMsg:action.payload,apiStatus:-1 } }

  case 'GOT_RESETMAIL_DATA':
    return {...state, passResetMail:action.payload }
  case 'FAILED_RESETMAIL_DATA':
      return {...state, passResetMail:action.payload }

  case 'GETTING_CHECKTOKEN_DATA':
  return {...state }
  case 'GOT_CHECKTOKEN_DATA':
    return {...state, checkTokenMail:action.payload }
  case 'FAILED_CHECKTOKEN_DATA':
      return {...state, checkTokenMail:{errorMsg:action.payload } }
 
  case 'GETTING_RESETPASS_DATA':
  return {...state, resetPassword:{resetPass:"pending"} }
  case 'GOT_RESETPASS_DATA':
    return {...state, resetPassword:{resetPass:true} }
  case 'FAILED_RESETPASS_DATA':
      return {...state, resetPassword:{errors: action.payload, resetPass:false } }
  case 'COMPONENT_LOADER_LOGOUT':
    return {...state,loaderLogout:action.payload}

  case 'GOT_ADMIN_TO_BROKER_INFO':
    return {...state, adminToBrokerInfo:action.payload }
  case 'FAILED_ADMIN_TO_BROKER_INFO':
      return {...state, adminToBrokerInfo:action.payload }
  case 'FAILED_TO_LOGIN_WITH_GOOGLE' :
    return{...state, loggedWithGoogleInfo:action.payload}

  case 'LOGOUT_SUCCESS':
    return {...state,logoutStatus:action.payload}

  case 'SET_SELECTED_TAB_HEADER':
    let levelNo = (state.user && state.user.levelNo) || (state.getUserInfo && state.getUserInfo.data && state.getUserInfo.data.levelNo )
    let parentTab = action.payload && action.payload.parentTab
    let childTab = action.payload && action.payload.childTab

    if( ( parentTab && parentTab.isNotAllowedFor && !ShowHideTab(parentTab,levelNo) )
        || ( childTab && childTab.isNotAllowedFor && !ShowHideTab(childTab,levelNo) ) ){
          if(state.user){
            if(state.user.userType =="client")
              window.location.href = "/client/dashboard"
            if(state.user.userType =="broker")
              window.location.href = '#/broker/dashboard'
          }
    }
    return {...state,setSelectedTab:action.payload}

  case 'LOGIN_STEP_ONE_SUCCESS' :
    return {...state,otpDetails : action.payload}

  case 'OTP_SENT_SUCCESSFULLY':
    return {...state,otpExpiryTime: action.payload && action.payload.result,successOtpStatus: action.payload && action.payload.status,
      notificationStatus: action.payload && action.payload.message , errorNotification : false }

  case 'FAILED_TO_SEND_OTP' :
    return {...state,otpExpiryTime:null, notificationStatus: action.payload && action.payload.message, errorNotification : true ,failedOtpStatus: action.payload && action.payload.status}

  case 'LOADER_SENT_OTP':
    return { ...state, loaderOtpCheck: action.payload }

  case 'FAILED_OTP_CONFIRMATION' :
    return {...state,failedOTPConfirmation :action.payload}

  case 'GOT_OTP_EXPIRY_TIME_SUCESSFULLY' :
    return {...state,otpExpiryTime: action.payload }

  case 'FAILED_GETTING_OTP_EXPIRY' :
    return {...state,otpExpiryTime: null}

  case 'CLOSE_NOTIFICATION' : 
     return {...state, notificationStatus : null , errorNotification : false}

  case 'GOT_ALLOWED_FEATURE_LIST' :
    return {...state,allowedFeatureList: action.payload }

  case 'FAILED_ALLOWED_FEATURE_LIST' :
    return {...state,allowedFeatureList: action.payload}

  case 'GOT_FEATURE_PERMISSIONS':
    return {...state, featurePermissions: action.payload }

    case 'STORE_PREVIOUS_DATA':
      return {...state, featurePermissionsData: action.payload }

  case 'FAILED_TO_UPDATE_FEATURE_PERMISSIONS':
    return {...state, featurePermissions : [] }

  case "UPDATE_FEATURE_PERMISSIONS":
    return {...state, permissionNotification : action.payload }
  case "FAILED_TO_UPDATE_FEATURE_PERMISSIONS":
    return {...state, permissionNotification : action.payload }

  case "CLEAR_PERMISSION_NOTIFICATION":
    return { ...state, permissionNotification: null }

  case 'SAVE_RECAPTCHA_TOKEN':
    return { ...state, recaptchaToken: action.payload }
  
  case 'ADD_REFRESH_KEY':
    return { ...state, refreshKey: action.payload }  

  case 'SHOW_RECAPTCHA_POPUP':
    return { ...state, showRecaptchaPopup: action.payload }

  case 'COMPONENT_LOADER_HIDE_METRICS':
    return { ...state, showHideMetricsLoader: action.payload }
  case 'UPDATE_HIDE_METRICS_STATUS':
    return { ...state, showHideMetrics: action.payload }
  case 'UPDATE_HIDE_METRICS_STATUS_FAILED':
    return { ...state, showHideMetrics: action.payload }

  case 'COMPONENT_LOADER_SUBSCRIBE':
    return {...state, subscribeLoader: action.payload }
  case 'SUCCESSFULL_SUBSCRIBE':
    return {...state, subscribeData: action.payload }
  case 'FAILED_SUBSCRIBE':
      return {...state, subscribeData: action.payload }
  case 'COMPONENT_LOADER_CHECKTOKEN':
      return {...state,checkTokenLoader:action.payload}
  case 'ADD_VIEW_ACTION':
    return{...state,addViewActionType :action.payload}
  case 'FAILED_ADMIN_TO_BROKER_LOGIN_HISTORY':
    return {...state, getAdminLoginHistory : action.payload}
  case 'GOT_ADMIN_TO_BROKER_LOGIN_HISTORY':
    return {...state, getAdminLoginHistory : action.payload}

  case 'CHECK_MAINTAINANCE_SUCCESS':
  case 'FAILED_CHECK_MAINTAINANCE':
    return { ...state, maintainanceResponse: action.payload }

  case 'CHECK_FOR_MAINTAINANCE':
    return { ...state, checkForMaintainance: action.payload }

  case 'GOT_CHANGEPASS_DATA':
    return {...state , changePass : action.payload, errorNotification:false}
  case 'FAILED_CHANGEPASS_DATA':
    return {...state , changePass : action.payload, errorNotification:true}
  
  case 'FRONTEND_PARAM':
    return {...state , feParams : action.payload}

  case 'SET_MASKING_FLAG':
    return {...state , maskClientData : action.payload}
  case 'SET_ALERT_FALSE':
    return {...state,theme:{...state.theme,showAlert:action.payload}}
    default:
      new Error('No action type matched in Login reducer')
  }
  return state
}